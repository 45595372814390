import React, { useEffect, useState } from 'react';
// import { navigate } from '@reach/router';
import { useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

// Animations
import gsap from 'gsap';

// import LocaleContext from '../context/Locale';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';
// import FullScreenContainer from '../components/FullScreenContainer';
import RotateDevice from '../components/RotateDevice';

import Layout from '../components/Layout';

// Styled Components
const Tale = styled.div`
  width: 10vw;
  opacity: 0;
  background-color: black;
`;

const BackgroundSection = ({
  className,
  children,
  image: imageData,
  onBackgroundLoaded,
}) => (
  <BackgroundImage
    Tag="section"
    className={className + ' full-screen w-full'}
    style={{
      paddingTop: '4rem',
    }}
    fluid={imageData}
    backgroundColor={`#040e18`}
    onLoad={onBackgroundLoaded}
  >
    {children}
  </BackgroundImage>
);

const SectionWithCoverBackground = styled(BackgroundSection)`
  width: 100%;
  background-position: center bottom;
  background-repeat: repeat;
  background-size: 100%;
  background-attachment: fixed;
  position: fixed;
  height: auto;
  min-height: 100vh;
  padding-bottom: 100vh;
`;

// Main Component
function IndexPage() {
  // Init state and variables
  const [mainAnimation, setMainAnimation] = useState(null);
  const [isActivatedAnimation, activateAnimation] = useState(false);
  let fullHeight = 0;

  // Define when is good time to trigger animation
  const triggerMainAnimationIfLandscape = () => {
    if (typeof window !== 'undefined') {
      if (
        mainAnimation !== null &&
        mainAnimation.paused() &&
        window.innerWidth > window.innerHeight &&
        isActivatedAnimation
      ) {
        mainAnimation.resume();
      }
    }
  };

  const triggerMainAnimation = () => {
    if (typeof window !== 'undefined') {
      console.log('triggerMainAnimation', mainAnimation, isActivatedAnimation);
      if (
        mainAnimation !== null &&
        mainAnimation.paused() &&
        isActivatedAnimation
      ) {
        mainAnimation.resume();
      }
    }
  };

  // Activate animation if not activated yet
  useEffect(() => {
    triggerMainAnimation();
  }, [isActivatedAnimation, triggerMainAnimation]);

  useEffect(() => {
    // Setup settings after mounting all elements

    // * Calculate height of the band-members container FIRST, before hiding elements
    const windowHeight =
      typeof window !== 'undefined' ? window.innerHeight : 100;

    fullHeight =
      typeof document !== 'undefined'
        ? document.getElementById('band-members').offsetHeight + windowHeight
        : 0;

    // // Setup data-height attribute to read from it later
    // if (typeof document !== 'undefined') {
    //   document.querySelector(
    //     'section.main-page-screen'
    //   ).dataset.height = fullHeight;
    // }

    // Setup initial state
    const initialAnimationSetup = gsap.timeline();

    initialAnimationSetup.set('.tale', { opacity: 0 });
    initialAnimationSetup.set('.note', { opacity: 0 });
    initialAnimationSetup.set('.member-image-second', { opacity: 0 });
    initialAnimationSetup.set('.band-member', { opacity: 0 });
    initialAnimationSetup.set('#band-members', { display: 'none' });
    initialAnimationSetup.set('.band-member-info-box', { zIndex: 60 });
    initialAnimationSetup.to('#lines path', { opacity: 0 });

    setMainAnimation(gsap.timeline({ paused: true, delay: 1 }));
  }, []);

  // Prepare animation
  useEffect(() => {
    if (mainAnimation !== null) {
      // Add orientation change listener to trigger main animation if landscape
      if (typeof window !== 'undefined') {
        window.addEventListener('orientationchange', () => {
          const afterOrientationChange = () => {
            triggerMainAnimation();

            window.removeEventListener('resize', afterOrientationChange);
          };

          window.addEventListener('resize', afterOrientationChange);
        });
      }
      //
      mainAnimation.delay(1);
      //
      // // Animate lines
      // mainAnimation.fromTo(
      //   '#lines path',
      //   { x: '-300%', opacity: 0.5 },
      //   { x: '0%', opacity: 0.5 },
      //   '+=1'
      // );
      //
      // // Animate notes
      // mainAnimation.fromTo(
      //   '.note',
      //   { opacity: 0 },
      //   { duration: 1.2, stagger: 0.2, opacity: 1 },
      //   '+=1'
      // );

      // Animate covering tales
      mainAnimation.fromTo(
        '.tale',
        { opacity: 0 },
        { duration: 0.7, stagger: 0.2, opacity: 1 },
        '-=2'
      );

      // Animate lines
      // mainAnimation.to('#lines path', { x: '250%' }, '=-0.5');

      // mainAnimation.to('.note', {
      //   // ease: "power3.inOut",
      //   opacity: 0,
      //   stagger: 0.1,
      //   duration: 0.5,
      //   // x: (index) => {
      //   //   switch (index) {
      //   //     case 0:
      //   //       return "40vw";
      //   //     case 1:
      //   //       return "35vw";
      //   //     case 2:
      //   //       return "20vw";
      //   //     case 3:
      //   //       return "15vw";
      //   //     case 4:
      //   //       return "0vw";
      //   //     case 5:
      //   //       return "-5vw";
      //   //     case 6:
      //   //       return "-10vw";
      //   //     case 7:
      //   //       return "-20vw";
      //   //     case 8:
      //   //       return "-35vw";
      //   //     case 9:
      //   //       return "-45vw";
      //   //   }
      //   // },
      //   // y: "-50vh"
      // });

      if (true) {
        mainAnimation.to('.tale', { opacity: 0, stagger: 0.2, duration: 0.5 });

        // Setup height of section main-page-screen
        if (typeof document !== 'undefined') {
          // Get height of band-members container
          // Setup height of the main page screen
          // mainAnimation.set("section.main-page-screen", { height: document.querySelector("section.main-page-screen").dataset.height });
          // mainAnimation.set('section.main-page-screen', {
          //   height: function() {
          //     return (
          //       (typeof document !== 'undefined' &&
          //       !!document.getElementById('band-members')
          //         ? document.getElementById('band-members').offsetHeight
          //         : 0) +
          //       (typeof window !== 'undefined' ? window.innerHeight : 100) +
          //       50
          //     );
          //   },
          // });
        }

        // Show band members
        mainAnimation.to(
          '.band-member',
          { opacity: 1, stagger: 0.3, duration: 0.7, delay: 4 },
          '-=2.5'
        );

        mainAnimation.set('#band-members', { display: 'grid' }, '-=4');
      }

      setTimeout(() => {
        // Try to run animation
        triggerMainAnimation();
      }, 1000);
    }
  }, [mainAnimation]);

  const images = useStaticQuery(graphql`
    query {
      desktop: file(relativePath: { eq: "band.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      allFile(filter: { absolutePath: { regex: "/members/" } }) {
        edges {
          node {
            name
            dir
            absolutePath
            base
            childImageSharp {
              fluid(quality: 90, maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  `);

  // console.log(images.allFile);

  const membersImages = images.allFile.edges.map(edge => edge.node);

  const members = [
    {
      key: 'member1',
      name: 'Kasia Mętel',
      description:
        'Znacie takie powiedzenie, że z kimś można "konie kraść" albo "ona to do tańca i do różańca" – oba idealnie określają Kaśkę! Ta niesamowita dziewczyna z folklorem związana jest od kołyski, w jej domu kultywuje się tradycje pasterskie i folklor, może dlatego Kasi z taką lekkością przychodzi granie i śpiewanie.<br /><br />Ma nie tylko mocny góralski głos, jest także znakomity muzykiem, gra na skrzypcach i basach wspierając lokalne zespoły folklorystyczne i kapele babiogórskie, z którymi związana jest od lat. Kocha tradycje, region i kulturę babiogórską. Jest duszą towarzystwa, uśmiechnięta, podchodząca do życia z ogromną otwartością i wielkim poczuciem empatii.<br /><br />Kasia to także, a raczej przede wszystkim dobry człowiek, umiejący uważnie słuchać drugiego człowieka i patrzeć na świat z dystansem, którego tak często nam brakuje.',
      instruments: 'wokal',
    },
    {
      key: 'member3',
      name: 'Madzia Denis',
      description:
        'Madzia podobnie jak reszta dziewczyn od dziecka związana jest z folklorem, pierwsze kroki stawiała w zespole JUZYNA, który do tej pory wspiera. W rodzinnym domu miłość do tradycji i regionu od dziecka wlewali w nią rodzice, mama czynnie działa w zespole CIEŚLICA, obie często wspierają Babiogórskie Centrum Kultury w różnego rodzaju przedsięwzięciach związanych z kulturą ludową.<br /><br />Madzia jest „najdelikatniejszą” częścią zespołu, subtelna uroda, spokój który z niej promieniuje i rozwaga działa kojąco w wielu trudnych sytuacjach. Nie myślcie jednak, że ta delikatność wiąże się w jakikolwiek sposób ze stereotypowym myśleniem o kobietach „księżniczkach”. Madzia doskonale wie czego chce, jest silną kobietą, która nie daje sobą rządzić, wręcz przeciwnie – to ona często sprowadza do pionu męską część zespołu. Może ma to związek z tym, że ma czterech braci z którymi przeszła nie jedną „wojnę” :)',
      instruments: 'wokal',
    },
    {
      key: 'member4',
      name: 'Martyna Giertuga',
      description:
        'Martyna - oaza spokoju, kobieta z niesamowitą umiejętnością słuchania i słyszenia drugiego człowieka oraz ogromnymi pokładami empatii. To ona łagodzi napięte sytuacje, wysłuchuje i próbuje znaleźć odpowiednie dla nich rozwiązanie. Niezwykle pracowita i ambitna. Z folklorem związana od dziecka przez zespół JUZYNA, który do tej pory wspiera.',
      instruments: 'wokal',
    },
    {
      key: 'member5',
      name: 'Julia Pacyga',
      description:
        'Pierwszą styczność z folklorem zawdzięcza regionalnemu zespołowi JUZYNA, z PotOCKiem zaś związana jest od początku jego istnienia. Julka to wręcz wzorowy typ marzycielki, wpatrzonej w niebo, zamyślonej, nucącej sobie coś pod nosem i nieustannie tworzącej coś niezwykłego w swojej głowie. Ma swój świat, do którego dzięki przyjaźni, czasami nas wpuszcza, świat który uwielbiamy!<br /><br />Julia ma jeszcze jedną cechę, łatwo wierzy w różnego rodzaju opowieści tworzone przez bogatą wyobraźnię męskiej części zespołu, w związku z czym często wpada w pułapki „potockowych” żartów :) A i jeszcze jedno... te wielkie brązowe oczy, ten piekny uśmiech – to właśnie JULKA!',
      instruments: 'wokal',
    },
    {
      key: 'member6',
      name: 'Marek "Maro" Nitoń',
      description:
        'Dobry zespół rockowy nie może istnieć bez mocno wybrzmiewającej gitary basowej, w Potocku nie mamy z tym problemu. Ścieżka muzyczna Mara jest imponująca, grał w zespołach takich jak: Narwana, Body Mind Relation, The Chilloud, Zimne Rosoły i Quendo Fendo. Jest także mocno związany z folklorem, przez 8 lat był aktywnym członkiem zespołu Polana Makowska, przez 2 lata zespołu Ziemia Suska.<br /><br />Maro jest niezwykle pracowitym, solidnym i zwracającym uwagę na szczegóły członkiem zespołu, działającym trochę jak „szwajcarski zegarek”. Co jeszcze można napisać o Marku? Jeśli zobaczycie dobrze wyglądającego mężczyznę, idącego przez Maków Podhalański, to prawdopodobnie będzie to Maro :D.',
      instruments: 'gitara basowa',
    },
    {
      key: 'member7',
      name: 'Krzysztof "Puchacz" Kumorek',
      description:
        'Puchacz jest bez wątpienia muzykiem z ogromnym doświadczeniem, przez wiele lat związany z zespołem Body Mind Relation i innymi kapelami rockowymi z regionu. Z PotOCKiem jest od początku istnienia zespołu.<br /><br />Krzysiek w zespole prawie nigdy się nie unosi, każdą kwestię dokładnie analizuje, uwielbiamy wręcz czytać jego komentarze na grupie, w których nigdy emocje nie biorą góry nad rozumem. Zaangażowany, dobrze zorganizowany, ze specyficznym poczuciem humoru, które na długo zapada w pamięć wszystkim, którzy poznają Puchacza.<br /><br />Jeśli dobrze wsłuchacie się w nasze utwory poznacie brzmienie jego gitary, to jest prawdziwa „żyleta”! Co jeszcze? Jeśli szukacie aktora do filmu lub klipu polecamy Wam właśnie Puchacza, ma ukryty talent aktorski! I już na koniec, kiedyś powiedział: „Będę pchał ten wózek nawet jeżeli będzie na równi pochyłej." ... Tak, na Puchacza zawsze można liczyć!',
      instruments: 'gitara rytmiczna',
    },
    {
      key: 'member8',
      name: 'Mieszko Krzyś',
      description:
        'Mieszko Krzyś - jeden z najbardziej rozpoznawalnych muzyków w regionie, znakomity gitarzysta i wokalista. Od dziecka miał styczność z muzyką, najpierw był fortepian, a potem już ukochana gitara. Od 16 roku życia nieprzerwanie na scenie. Związany z zespołami: Narwana, The Chilloud, Let Him Run.<br /><br />Obecnie tworzy skład Potocku oraz Honey Moon. Współtwórca utworów, które zdobywały pierwsze miejsca na festiwalach oraz rozgłośniach radiowych w Polsce, nagrodzony również za najlepszy wokal festiwalu w Warszawie (jury Marek Raduli oraz Krzysztof Ścierański). Przez rok uczęszczał do Krakowskiej Szkoły Jazzu i Muzyki Rozrywkowej.<br /><br />Od czterech lat pracuje w domu kultury w Makowie Podhalańskim jako nauczyciel gitary. A w zespole... jest jedną z tych osób, której żarty, dosłownie, powalają 🙂 zaś jego kreatywny umysł wspiera nas w tworzeniu najciekawszych aranżacji 🙂',
      instruments: 'gitara elektryczna, wokal',
    },
    {
      key: 'member9',
      name: 'Krzysztof "Biały" Mędrala',
      description:
        'Każdy zespół ma swojego lidera, „ojca” który trzyma rękę na pulsie. BIAŁY – Krzysiek Mędrala, Potock-owi oddałby serce, w chwilach zwątpienia to właśnie on podnosi i motywuje. Z perkusją związany od zawsze, przeszedł przez wiele kapel i zespołów - m.in. Body Mind Relation, Narvana, Moonlight oraz The Chilloud.',
      instruments: 'perkusja',
    },
    {
      key: 'member10',
      name: 'Adam "Raymel" Kukla',
      description:
        'PotOCK tworzy dziewięć osób - temperamentnych, radosnych, pełnych życia ludzi, których czasami trudno ogarnąć :). Wśród nich niezwykle pracowita „mrówka”, bez której wiele rzeczy/spotkań/prób by się nie odbyło, albo odbyłoby się w zupełnej rozsypce.<br /><br />Adam jest wszechstronnym gitarzystą, współtworzył zespoły Body Mind Relation, The Chilloud, Sermon. Wraz z francuskim muzykiem Simeonem Lenoir realizował projekt The Cosmopolitan Show, jest też twórcą licznych własnych projektów, z PotOCK-iem związany od początku istnienia.<br /><br />„Adam czy możesz przywieźć? Adam czy możesz zorganizować? Adam czy byłbyś w stanie załatwić? Adam możesz mnie/nas podwieźć?”  Tak, Adam bardzo częste słyszy takie słowa, to on dba o wiele rzeczy, organizuje logistyczny układ w „kanciapie”, układa, przekłada i po prostu pomaga.<br /><br />Całe serce wkłada w zespół, tworzy, aranżuje, bawi się muzyką i słowami, dzięki czemu dawne piosenki nabierają zupełnie nowego kształtu. Jeśli będziecie kiedyś na koncercie PotOCKu to poznacie Adama na scenie od razu – to człowiek „tańczący” z gitarą.',
      instruments: 'gitara solowa, wokal',
    },
  ].map(member => {
    return {
      ...member,
      imageFirst: membersImages.find(i => i.name === member.key + '_first'),
      imageSecond: membersImages.find(i => i.name === member.key + '_second'),
    };
  });

  const toggleMemberInfo = member => {
    if (
      parseInt(
        document.querySelector('#' + member.key + ' .band-member-info-box')
          .style.opacity
      ) === 1
    ) {
      hideMemberInfo(member);
    } else {
      showMemberInfo(member);
    }
  };

  const showMemberInfo = member => {
    const t4 = gsap.timeline();

    t4.fromTo(
      '#' + member.key + ' .band-member-info-box',
      { opacity: 0 },
      { opacity: 1, zIndex: 60, y: '-100%' }
    );
  };

  const hideMemberInfo = member => {
    const t6 = gsap.timeline();

    t6.fromTo(
      '#' + member.key + ' .band-member-info-box',
      { opacity: 1 },
      { opacity: 0, zIndex: 60, y: '0%' }
    );
  };

  const showSecondPhoto = member => {
    const t2 = gsap.timeline();
    t2.fromTo(
      '#' + member.key + ' .member-image-second',
      { opacity: 0 },
      { opacity: 1 }
    );
  };

  const hideSecondPhoto = member => {
    const t3 = gsap.timeline();

    t3.fromTo(
      '#' + member.key + ' .member-image-second',
      { opacity: 1 },
      { opacity: 0 }
    );

    hideMemberInfo(member);
  };

  return (
    <Layout fullPage={true} shop={true}>
      <SectionWithCoverBackground
        onBackgroundLoaded={() => activateAnimation(true)}
        className="main-page-screen"
        image={images.desktop.childImageSharp.fluid}
      >
        <div>
          <div className="">
            <div className="background-image">
              <div
                id="band-members"
                className="band-members grid grid-cols-1 lg:grid-cols-2 min-h-screen p-10 gap-5"
              >
                {members.map((member, index) => {
                  return (
                    <div
                      id={member.key}
                      key={index}
                      className="band-member rounded cursor-pointer relative z-40 overflow-hidden mx-auto w-full"
                      onMouseEnter={() => showSecondPhoto(member)}
                      onMouseLeave={() => hideSecondPhoto(member)}
                      onClick={() => toggleMemberInfo(member)}
                      style={{
                        boxShadow: '0 8px 16px rgba(0, 0, 0, 0.32)',
                        maxHeight: '100vh',
                        maxWidth: '100vh',
                      }}
                    >
                      <Img
                        fluid={member.imageFirst.childImageSharp.fluid}
                        alt={member.name}
                        className="rounded member-image-first relative z-40 w-full"
                      />
                      <Img
                        fluid={member.imageSecond.childImageSharp.fluid}
                        alt={member.name}
                        className="rounded member-image-second absolute top-0 z-50 w-full"
                        style={{ position: 'absolute' }}
                      />
                      <div
                        className="band-member-info-box opacity-0 w-full h-full absolute p-4 text-white overflow-y-auto"
                        style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
                      >
                        <h3 className="font-bold">{member.name}</h3>
                        <span className="text-xs pt-0 mt-0">
                          {member.instruments}
                        </span>
                        <p
                          className="mt-2"
                          style={{
                            fontSize: '1.3rem',
                            lineHeight: '2rem',
                            maxHeight: '100%',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: member.description,
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="tales flex flex-row absolute bg-transparent w-full h-full left-0 top-0">
                {new Array(10).fill(0).map((i, index) => (
                  <Tale className="tale" key={index} />
                ))}
              </div>

              <div
                className="notes fixed bottom-0 w-full overflow-hidden"
                style={{ left: '50%', transform: 'translateX(-50%)' }}
              >
                <svg
                  style={{
                    minWidth: '100%',
                    width: 'calc(100vw - 30px)',
                    height: 'auto',
                    overflow: 'hidden',
                  }}
                  height="508"
                  viewBox="0 0 1440 508"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="notes">
                    <g id="lines">
                      <path
                        id="path9"
                        d="M0 1H1440"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <path
                        id="path11"
                        d="M0 325.049H1440"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <path
                        id="path13"
                        d="M0 216.902H1440"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <path
                        id="path15"
                        d="M0 108.951H1440"
                        stroke="white"
                        strokeWidth="2"
                      />
                      <path
                        id="path17"
                        d="M0 433H1440"
                        stroke="white"
                        strokeWidth="2"
                      />
                    </g>
                    <path
                      className="note"
                      id="note1"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M166.174 109H172.037C173.502 118.348 175.334 126.219 177.777 132.615C180.098 138.888 182.907 144.3 185.96 148.851C189.135 153.402 193.899 159.182 200.25 166.316C206.601 173.45 211.608 179.477 215.517 184.52C227.242 199.648 233.104 215.515 233.104 231.996C233.104 248.969 226.02 269.633 211.608 293.986H207.7C209.532 289.681 211.73 284.761 214.173 279.227C216.494 273.569 218.57 268.526 220.28 263.975C221.868 259.424 223.211 254.873 224.188 250.322C225.043 245.772 225.532 241.344 225.532 236.916C225.532 229.905 224.188 222.894 221.379 215.884C218.57 208.75 214.54 202.231 209.532 196.204C204.402 190.177 198.662 185.381 192.189 181.568C185.716 177.878 178.998 175.787 172.037 175.418V314.649C172.037 322.521 169.716 329.655 164.831 335.805C160.067 341.954 153.839 346.751 146.266 350.072C138.816 353.393 131.366 354.992 123.916 354.992C116.832 354.992 110.847 353.147 106.084 349.58C101.443 346.013 99 340.725 99 333.837C99 326.58 101.443 319.815 106.206 313.542C110.969 307.393 117.076 302.473 124.404 298.906C131.732 295.339 138.816 293.494 145.656 293.494C155.182 293.494 162.022 295.339 166.174 299.152V173.45V109Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.367694"
                    />
                    <path
                      className="note"
                      id="note2"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M251.278 404.513V217H257.541C258.384 226.339 260.432 233.617 263.804 238.589C267.056 243.562 272.476 249.505 280.184 256.176C287.772 262.969 293.673 269.154 298.009 274.734C304.272 282.617 308.969 290.259 312.341 297.536C315.593 304.813 317.279 313.182 317.279 322.764C317.279 330.163 316.195 337.804 313.907 345.809C318.122 351.509 320.17 360.485 320.17 372.492C320.17 380.255 319.206 387.896 317.159 395.537C314.991 403.179 311.98 409.849 308.005 415.429H304.031C311.619 400.631 315.352 387.654 315.352 376.616C315.352 355.512 303.188 338.774 278.739 326.403C276.932 325.432 274.403 323.856 271.151 321.551C267.779 319.368 265.129 317.549 263.443 316.457C261.636 315.365 259.709 314.274 257.541 313.546V420.28C257.541 427.558 255.132 434.229 250.435 440.172C245.618 446.115 239.596 450.724 232.249 454.12C224.902 457.395 217.676 459.093 210.57 459.093C203.705 459.093 198.044 457.516 193.588 454.362C189.011 451.209 186.482 446.479 186 440.172C186 433.016 188.409 426.223 193.347 419.795C198.285 413.367 204.548 408.273 212.015 404.513C219.603 400.753 226.829 398.933 233.935 398.933C241.282 398.933 247.063 400.753 251.278 404.513ZM258.986 262.119C258.986 272.793 261.757 282.011 267.297 289.773C272.957 297.536 280.906 306.39 291.385 316.457C301.743 326.524 308.246 334.044 310.896 339.138C311.498 335.984 311.739 332.588 311.739 328.95C311.739 320.945 310.173 313.425 306.921 306.633C303.67 299.719 299.454 293.533 294.396 288.197C289.337 282.86 283.797 278.13 277.895 273.885C271.873 269.639 265.611 265.758 258.986 262.119Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.362591"
                    />
                    <path
                      className="note"
                      id="note3"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M469.902 109V311.795C469.902 319.315 467.494 326.107 462.676 332.05C457.859 338.115 451.716 342.845 444.369 346.12C436.902 349.395 429.676 351.093 422.57 351.093C415.825 351.093 410.164 349.273 405.588 345.756C401.011 342.117 398.482 337.145 398 330.716C398.241 323.681 400.891 317.011 405.829 310.825C410.767 304.639 417.029 299.666 424.376 296.028C431.723 292.268 438.949 290.448 445.935 290.448C453.041 290.448 459.183 292.51 464.121 296.513V109H469.902Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.362591"
                    />
                    <path
                      className="note"
                      id="note4"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M500.242 261H506.023C507.468 270.218 509.275 277.98 511.684 284.287C513.972 290.473 516.742 295.81 519.753 300.298C522.884 304.785 527.582 310.486 533.844 317.521C540.107 324.555 545.045 330.499 548.899 335.471C560.462 350.39 566.243 366.036 566.243 382.289C566.243 399.027 559.257 419.403 545.045 443.419H541.191C542.998 439.173 545.166 434.322 547.575 428.864C549.863 423.285 551.91 418.312 553.597 413.824C555.162 409.336 556.487 404.849 557.451 400.361C558.294 395.873 558.776 391.507 558.776 387.141C558.776 380.227 557.451 373.314 554.681 366.4C551.91 359.365 547.936 352.937 542.998 346.994C537.939 341.051 532.279 336.32 525.895 332.56C519.512 328.922 512.888 326.86 506.023 326.496V463.795C506.023 471.558 503.735 478.592 498.917 484.657C494.22 490.721 488.077 495.452 480.61 498.726C473.263 502.001 465.917 503.578 458.57 503.578C451.584 503.578 445.683 501.759 440.986 498.241C436.409 494.724 434 489.508 434 482.716C434 475.56 436.409 468.889 441.106 462.704C445.803 456.639 451.825 451.788 459.051 448.27C466.278 444.753 473.263 442.933 480.008 442.933C489.402 442.933 496.147 444.753 500.242 448.513V324.555V261Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.362591"
                    />
                    <path
                      className="note"
                      id="note5"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M730.902 474.093V271.298C730.902 263.778 728.494 256.986 723.676 251.042C718.859 244.978 712.716 240.248 705.369 236.973C697.902 233.698 690.676 232 683.57 232C676.825 232 671.164 233.819 666.588 237.337C662.011 240.975 659.482 245.948 659 252.377C659.241 259.411 661.891 266.082 666.829 272.268C671.767 278.454 678.029 283.427 685.376 287.065C692.723 290.825 699.949 292.645 706.935 292.645C714.041 292.645 720.183 290.583 725.121 286.58V474.093H730.902Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.362591"
                    />
                    <path
                      className="note"
                      id="note6"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M766.902 323.093V120.298C766.902 112.778 764.494 105.986 759.676 100.042C754.859 93.978 748.716 89.2477 741.369 85.9729C733.902 82.6981 726.676 81 719.57 81C712.825 81 707.164 82.8194 702.588 86.3367C698.011 89.9754 695.482 94.9483 695 101.377C695.241 108.411 697.891 115.082 702.829 121.268C707.767 127.454 714.029 132.427 721.376 136.065C728.723 139.825 735.949 141.645 742.935 141.645C750.041 141.645 756.183 139.583 761.121 135.58V323.093H766.902Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.362591"
                    />
                    <path
                      className="note"
                      id="note7"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M844.174 261H850.037C851.502 270.348 853.334 278.219 855.777 284.615C858.098 290.888 860.907 296.3 863.96 300.851C867.135 305.402 871.899 311.182 878.25 318.316C884.601 325.45 889.608 331.477 893.517 336.52C905.242 351.648 911.104 367.515 911.104 383.996C911.104 400.969 904.02 421.633 889.608 445.986H885.7C887.532 441.681 889.73 436.761 892.173 431.227C894.494 425.569 896.57 420.526 898.28 415.975C899.868 411.424 901.211 406.873 902.188 402.322C903.043 397.772 903.532 393.344 903.532 388.916C903.532 381.905 902.188 374.894 899.379 367.884C896.57 360.75 892.54 354.231 887.532 348.204C882.402 342.177 876.662 337.381 870.189 333.568C863.716 329.878 856.998 327.787 850.037 327.418V466.649C850.037 474.521 847.716 481.655 842.831 487.805C838.067 493.954 831.839 498.751 824.266 502.072C816.816 505.393 809.366 506.992 801.916 506.992C794.832 506.992 788.847 505.147 784.084 501.58C779.443 498.013 777 492.725 777 485.837C777 478.58 779.443 471.815 784.206 465.542C788.969 459.393 795.076 454.473 802.404 450.906C809.732 447.339 816.816 445.494 823.656 445.494C833.182 445.494 840.022 447.339 844.174 451.152V325.45V261Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.367694"
                    />
                    <path
                      className="note"
                      id="note8"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M972.902 1V203.795C972.902 211.315 970.494 218.107 965.676 224.05C960.859 230.115 954.716 234.845 947.369 238.12C939.902 241.395 932.676 243.093 925.57 243.093C918.825 243.093 913.164 241.273 908.588 237.756C904.011 234.117 901.482 229.145 901 222.716C901.241 215.681 903.891 209.011 908.829 202.825C913.767 196.639 920.029 191.666 927.376 188.028C934.723 184.268 941.949 182.448 948.935 182.448C956.041 182.448 962.183 184.51 967.121 188.513V1H972.902Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.362591"
                    />
                    <path
                      className="note"
                      id="note9"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1154.76 399.844V215H1160.54V421.191C1160.42 427.741 1157.89 433.927 1152.95 439.87C1148.02 445.813 1142.11 450.664 1135.01 454.303C1128.02 457.82 1121.4 459.761 1115.26 460.004C1108.87 460.004 1103.81 459.155 1099.96 457.578C1096.23 456.001 1093.46 453.333 1091.65 449.452C1089.96 445.57 1089 440.112 1089 433.199C1089.24 425.8 1091.77 419.129 1096.71 413.186C1101.77 407.364 1108.03 402.755 1115.5 399.359C1122.96 396.084 1130.31 394.508 1137.42 394.508C1144.28 394.508 1150.06 396.206 1154.76 399.844ZM1111.64 422.161C1109.11 423.738 1105.86 426.892 1101.89 431.622C1097.91 436.352 1095.74 440.355 1095.5 443.751C1095.99 447.147 1098.15 449.209 1101.77 450.058C1105.38 450.058 1110.08 448.845 1115.86 446.419C1121.52 444.115 1127.06 440.84 1132.36 436.716C1135.85 434.412 1140.31 430.288 1145.61 424.466C1150.91 418.644 1153.56 413.914 1153.56 410.275C1153.56 406.879 1151.87 404.817 1148.26 403.968C1139.46 403.968 1127.3 410.033 1111.64 422.161Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.362591"
                    />
                    <path
                      className="note"
                      id="note10"
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1319.76 285.686V63H1325.66C1326.38 71.1264 1327.95 77.5547 1330.36 82.0424C1332.77 86.5301 1336.26 91.139 1341.2 95.748C1346.02 100.357 1352.88 106.785 1361.67 115.154C1377.45 130.437 1385.28 148.387 1385.28 169.249C1385.28 176.405 1384.32 183.44 1382.39 190.596C1384.32 196.66 1385.28 203.21 1385.28 210.002C1385.28 215.339 1384.56 221.525 1382.87 228.438C1386.72 233.411 1388.65 241.295 1388.65 252.211C1388.65 259.731 1387.69 267.493 1385.52 275.256C1383.47 283.018 1380.46 289.932 1376.37 296.117H1372.51C1379.74 280.956 1383.35 267.857 1383.35 256.82C1383.35 249.785 1382.03 243.357 1379.26 237.656C1376.49 231.834 1372.63 226.619 1367.7 221.889C1362.76 217.28 1357.46 212.913 1351.68 208.911C1345.9 205.029 1337.22 199.329 1325.66 192.051V301.454C1325.66 308.61 1323.25 315.16 1318.44 321.103C1313.62 327.046 1307.6 331.776 1300.25 335.172C1292.9 338.568 1285.68 340.267 1278.57 340.267C1271.34 340.267 1265.44 338.447 1260.87 334.93C1256.29 331.291 1254 325.954 1254 318.92C1254 312.006 1256.53 305.578 1261.47 299.635C1266.28 293.692 1272.55 288.961 1280.01 285.323C1287.48 281.805 1294.47 280.107 1301.09 280.107C1308.92 280.107 1315.18 281.927 1319.76 285.686ZM1326.5 107.149C1326.5 114.305 1328.19 120.491 1331.32 125.464C1334.45 130.558 1339.99 136.744 1347.82 144.021C1355.65 151.298 1361.79 157.242 1366.25 162.093C1370.71 166.945 1374.92 173.494 1378.78 181.621C1379.26 178.71 1379.5 175.92 1379.5 173.373C1379.5 163.427 1376.85 154.452 1371.43 146.326C1366.01 138.078 1359.99 131.407 1353.12 126.07C1346.38 120.734 1337.46 114.427 1326.5 107.149ZM1326.02 149.358C1326.5 156.999 1328.67 163.791 1332.53 169.856C1336.38 175.92 1341.2 181.742 1347.1 187.442C1353 193.022 1359.14 198.722 1365.41 204.665C1371.67 210.609 1376.13 216.067 1378.78 220.918C1379.02 219.584 1379.02 217.643 1379.02 215.096C1379.02 190.596 1361.43 168.643 1326.02 149.358Z"
                      fill="white"
                      stroke="#131516"
                      strokeWidth="0.362591"
                    />
                  </g>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </SectionWithCoverBackground>

      <RotateDevice />
    </Layout>
  );
}

export default IndexPage;
